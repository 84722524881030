import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #fff;
`

const PageTitle = styled.h1`
  font-size: 28px;
  font-weight: bold;
  color: #d5728a;
  margin-bottom: 20px;
  text-align: center;
`
const Paragraph = styled.p`
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
`

const CopyrightPage = () => {
  return (
    <Container>
      <Helmet>
        <title>{`網站權利聲明 - 美感BeauteFeel`}</title>
        <meta name='description' content={`美感BeauteFeel 網站權利聲明`} />
      </Helmet>
      <PageTitle>網站權利聲明</PageTitle>
      <Paragraph>
        本網站內所有資料，包括但不限於文字、內容、圖片、插圖、圖案、影像、音效等（合稱「網站內容」），其智慧財產權或其他法律權利，均屬
        BeauteFeel
        美感（下稱「本網站」）所有或本網站自合法權利人取得授權使用於本網站。
      </Paragraph>
      <Paragraph>
        本網站內容均受著作權法、商標法及其他智慧財產權和民法等法令規定所保護。我們歡迎您利用本網站網站之「分享」功能，宣傳本網站網站之資訊，您可以將網站內容轉載至社群軟體、網站、平台或部落格，但僅限於供個人或教育用途之範圍內。
      </Paragraph>
      <Paragraph>
        本網站作為第三方美容預約平台，僅提供美容店家資訊，不對美容店家提供之服務負任何責任。
      </Paragraph>
      <Paragraph>
        但本網站禁止使用者，在未經本網站書面同意或授權下，出於商業目的或意圖，而對於網站內容之全部或一部為任何形式之複製、重製、進行反向工程、散布、發行、展示、公開傳輸、修改、製作衍生作品等之利用，否則恐構成觸法行為。
      </Paragraph>
    </Container>
  )
}

export default CopyrightPage
