import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FaCheckCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { useSuccess } from '../context/SuccessContext'

const Container = styled.div`
  max-width: 600px;
  margin: 50px auto;
  padding: 40px 20px;
  text-align: center;
  background-color: #fff;
  border-radius: 8px;
`

const SuccessIcon = styled(FaCheckCircle)`
  color: #52c41a;
  font-size: 64px;
  margin-bottom: 24px;
`

const Title = styled.h2`
  color: #333;
  margin-bottom: 16px;
`

const Message = styled.p`
  color: #666;
  margin-bottom: 24px;
`

const Button = styled.button`
  background-color: #d5728a;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #c76279;
  }
`

const ReviewSuccessPage = () => {
  const navigate = useNavigate()
  const { successStates, resetSuccess } = useSuccess()

  useEffect(() => {
    console.log('ReviewSuccessPage mounted')
    console.log('Success states:', successStates)

    if (!successStates.review) {
      console.log('No review success state, redirecting to appointment list')
      navigate('/user-appointment-list', { replace: true })
      return
    }

    return () => {
      if (window.location.pathname !== '/review-success') {
        console.log('ReviewSuccessPage unmounting, resetting success state')
        resetSuccess('review')
      }
    }
  }, [successStates.review, navigate, resetSuccess])

  if (!successStates.review) {
    console.log('Rendering null due to no success state')
    return null
  }

  return (
    <Container>
      <SuccessIcon />
      <Title>評論提交成功！</Title>
      <Message>感謝您的寶貴意見，這對我們很重要。</Message>
      <Button onClick={() => navigate('/user-appointment-list')}>
        返回預約列表
      </Button>
    </Container>
  )
}

export default ReviewSuccessPage
