import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Divider, message, Button } from 'antd'
import { FaGoogle, FaFacebook, FaLine } from 'react-icons/fa'
import { useAuth } from '../hooks/AuthContext'
import api from '../utils/api'
import { Helmet } from 'react-helmet-async'

// 移動版組件
const ContainerMobile = styled.div`
  display: flex;
  padding: 50px 16px;
  background: linear-gradient(135deg, #f2b3c0 0%, #d5728a 100%);
  align-items: center;
  justify-content: center;
`

const BoxMobile = styled.div`
  width: 100%;
  max-width: 400px;
  background: #fff;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
`

const TitleMobile = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: #333;
`

const FormMobile = styled.form`
  display: flex;
  flex-direction: column;
`

const InputMobile = styled.input`
  padding: 12px;
  margin-top: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
`

const ButtonMobile = styled.button`
  padding: 12px;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 15px;
`

const SocialLoginContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  @media (min-width: 821px) {
    margin-top: 0px;
  }
`

const SocialButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  margin: 0 10px;
  cursor: pointer;
  font-size: 20px;
  color: white;
`

const GoogleButton = styled(SocialButton)`
  background-color: #db4437;
`

const FacebookButton = styled(SocialButton)`
  background-color: #4267b2;
`

const LineButton = styled(SocialButton)`
  background-color: #00c300;
`

const ErrorMessageMobile = styled.p`
  color: red;
`

const HintText = styled.p`
  font-size: 12px;
  color: #888;
  margin-top: 0px;
  margin-bottom: 5px;
  text-align: left;
  @media (min-width: 821px) {
    .HintText {
      margin-bottom: 15px;
    }
  }
`

// PC版組件
const ContainerPC = styled.div`
  display: flex;
  padding: 16px;
  height: 100vh;
  align-items: center;
  justify-content: center;
`

const BoxPC = styled.div`
  position: relative;
  display: flex;
  width: 900px;
  height: 550px;
  background: #fff;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  overflow: hidden;
`

const PanelPC = styled.div`
  position: absolute;
  display: flex;
  width: 50%;
  height: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  transition: all 0.6s ease-in-out;
  transform: ${({ $show }) => ($show ? 'translateX(0)' : 'translateX(-100%)')};
  opacity: ${({ $show }) => ($show ? '1' : '0')};
  z-index: ${({ $show }) => ($show ? '2' : '1')}; // 增加 z-index
`

const PanelRightPC = styled(PanelPC)`
  right: 0;
  transform: ${({ $show }) => ($show ? 'translateX(0)' : 'translateX(100%)')};
  z-index: ${({ $show }) => ($show ? '2' : '1')};
`

const OverlayPanelPC = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  width: 50%;
  height: 100%;
  color: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.6s ease-in-out;
  transform: ${({ $isSignUp }) =>
    $isSignUp ? 'translateX(-100%)' : 'translateX(0)'};
  z-index: 10; // 確保覆蓋層始終在最上層
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(135deg, #f2b3c0 0%, #d5728a 100%);
    z-index: -1;
  }
`

const OverlayPanelContentPC = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const FormPC = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TitlePC = styled.h2`
  margin-bottom: 10px;
  color: #333;
`

const InputPC = styled.input`
  padding: 10px;
  width: 300px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
`

const ButtonPC = styled(Button)`
  padding: 20px;
  margin-top: 10px;
  border-radius: 30px;
  border: none;
  background-color: #e14a63;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
`

const ErrorMessagePC = styled.p`
  color: red;
  margin-top: 0px;
`

const InputGroupPC = styled.div`
  margin-bottom: 15px;
  @media (min-width: 821px) {
    margin-bottom: 10px;
  }
`

const Text = styled.div`
  padding: 5px;
  font-size: 16px;
`

// 新增 email 驗證函數
const isValidEmail = (email) => {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return re.test(String(email).toLowerCase())
}

// 添加手機號碼驗證函數
const isValidTaiwanPhone = (phone) => {
  const phoneRegex = /^09\d{8}$/
  return phoneRegex.test(phone)
}

const LoginPage = () => {
  const { login, user } = useAuth()
  const [isSignUp, setIsSignUp] = useState(false)
  const [loginData, setLoginData] = useState({ username: '', password: '' })
  const [registerData, setRegisterData] = useState({
    username: '',
    name: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: ''
  })
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  // 新增用於手機版切換登入/註冊的狀態
  const [isMobileSignUp, setIsMobileSignUp] = useState(false)
  const [registerMethod, setRegisterMethod] = useState('email') // 'email' 或 'phone'
  const [phoneVerification, setPhoneVerification] = useState({
    phone: '',
    code: '',
    isCodeSent: false,
    countdown: 0
  })

  // 添加步驟狀態
  const [phoneRegisterStep, setPhoneRegisterStep] = useState(1) // 1: 手機號驗證, 2: 設置密碼

  // 添加驗證狀態
  const [isPhoneVerified, setIsPhoneVerified] = useState(false)

  useEffect(() => {
    // 根據當前URL路徑設置註冊/登入狀態
    const path = location.pathname
    if (path === '/register') {
      setIsSignUp(true)
      setIsMobileSignUp(true)
    } else {
      setIsSignUp(false)
      setIsMobileSignUp(false)
    }
  }, [location.pathname])

  // 修改切換函數,使其同時改變URL
  const toggleAuthMode = () => {
    const newIsSignUp = !isSignUp
    setIsSignUp(newIsSignUp)
    navigate(newIsSignUp ? '/register' : '/login')
  }

  const toggleMobileAuthMode = () => {
    const newIsMobileSignUp = !isMobileSignUp
    setIsMobileSignUp(newIsMobileSignUp)
    navigate(newIsMobileSignUp ? '/register' : '/login')
  }

  const navigateToPreviousPage = useCallback(() => {
    const redirectPath = localStorage.getItem('redirectAfterLogin')
    const previousPath = location.state?.from || '/'

    if (redirectPath) {
      // 如果有特定的重定向路徑（如預約管理頁面），優先使用
      localStorage.removeItem('redirectAfterLogin')
      navigate(redirectPath.replace('[username]', user.username))
    } else if (previousPath !== '/login' && previousPath !== '/register') {
      // 如果有前一頁且不是登入/註冊頁面，則返回前一頁
      navigate(previousPath)
    } else {
      // 默認導向首頁
      navigate('/')
    }
  }, [navigate, location.state?.from, user?.username])

  const handleLoginSuccess = async () => {
    message.success('已成功登入！')
    navigateToPreviousPage()
  }

  const handleLoginSubmit = async (e) => {
    e.preventDefault()
    setError('')
    setLoading(true)
    try {
      await login(loginData)
      handleLoginSuccess()
    } catch (error) {
      setError('登入失敗，請檢查您的帳號和密碼')
    } finally {
      setLoading(false)
    }
  }

  const handlePhoneRegisterSubmit = async (e) => {
    e.preventDefault()
    setError('')

    if (!isPhoneVerified) {
      setError('請先完成手機號驗證')
      return
    }

    try {
      // 驗證密碼
      if (
        !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/.test(registerData.password)
      ) {
        setError('密碼至少需要6位，必須包含至少一個字母和一個數字')
        return
      }

      if (registerData.password !== registerData.confirmPassword) {
        setError('兩次輸入的密碼不一致')
        return
      }

      setLoading(true)

      // 呼叫註冊 API
      const response = await api.post('/api/auth/verify-register', {
        phone: phoneVerification.phone,
        password: registerData.password
      })

      if (response.data.success) {
        message.success('註冊成功！')
        // 註冊成功後直接登入
        await login({
          username: phoneVerification.phone,
          password: registerData.password
        })
      }
    } catch (error) {
      setError(error.response?.data?.message || '註冊失敗')
    } finally {
      setLoading(false)
    }
  }

  const handleRegisterSubmit = async (e) => {
    e.preventDefault()

    if (registerMethod === 'phone') {
      handlePhoneRegisterSubmit(e)
    } else {
      handleEmailRegisterSubmit(e)
    }
  }

  const handleEmailRegisterSubmit = async (e) => {
    e.preventDefault()
    setError('')
    console.log('Starting email registration...')
    console.log('Register data:', registerData)

    // 去除所有字段的首尾空白
    const trimmedUsername = registerData.username.trim()
    const trimmedEmail = registerData.email.trim()
    const trimmedPassword = registerData.password.trim()
    const trimmedConfirmPassword = registerData.confirmPassword.trim()
    const trimmedName = registerData.name.trim()
    const trimmedPhone = registerData.phone.trim()

    // 檢查是否有空白字段
    if (
      !trimmedUsername ||
      !trimmedEmail ||
      !trimmedPassword ||
      !trimmedConfirmPassword ||
      !trimmedName ||
      !trimmedPhone
    ) {
      setError('所有字段均為必填項，不能留空')
      return
    }

    // 驗證手機號碼格式
    if (!isValidTaiwanPhone(trimmedPhone)) {
      setError('請輸入有效的台灣手機號碼（例：0912345678）')
      return
    }

    // 驗證帳號
    if (!/^[a-zA-Z0-9]{1,50}$/.test(trimmedUsername)) {
      setError('帳號能包含英文字母和數字，最多50個字符')
      return
    }

    // 驗證電子信箱
    if (!isValidEmail(trimmedEmail)) {
      setError('請輸入有效的電子信箱地址')
      return
    }

    // 驗證密碼
    if (trimmedPassword.length < 6) {
      setError('密碼至少需要6位英數字')
      return
    }

    // 檢查密碼是否包含至少一個字母和一個數字
    if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/.test(trimmedPassword)) {
      setError('密碼必須包含至少一個字母和一個數字')
      return
    }

    if (trimmedPassword !== trimmedConfirmPassword) {
      setError('密碼和確認密碼不匹配')
      return
    }

    setLoading(true)
    try {
      console.log('Sending registration request...')
      const response = await api.post(`/api/auth/register`, {
        username: trimmedUsername,
        email: trimmedEmail,
        password: trimmedPassword,
        name: trimmedName,
        phone: trimmedPhone
      })
      console.log('Registration response:', response)

      message.success('註冊成功，請檢查的電子信箱以完成電子郵件驗證。')
      navigate('/check-email')
    } catch (error) {
      console.error('Registration error:', error)
      setError(error.response?.data?.error || '註冊失敗，請檢查您的信息')
    } finally {
      setLoading(false)
    }
  }

  const handleGoogleLogin = () => {
    // 根據環境選擇正確的 URL
    const baseUrl =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:8080'
        : 'https://beautefeel.com'

    window.location.href = `${baseUrl}/api/auth/google`
  }

  const handleFacebookLogin = () => {
    // 實現 Facebook 登入邏輯
  }

  const handleLineLogin = () => {
    window.location.href = `https://beautefeel.com/api/auth/line`
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    handleResize() // 立即調用以設置初始狀態
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // 修改发送验证码的处理函数
  const handleSendVerificationCode = async () => {
    try {
      // 验证手机号格式
      if (!/^[0-9]{10}$/.test(phoneVerification.phone)) {
        setError('請輸入有效的手機號碼')
        return
      }

      // 调用发送验证 API
      const response = await api.post('/api/auth/send-code', {
        phone: phoneVerification.phone
      })

      if (response.data.success) {
        message.success('驗證碼已發送')
        // 开始倒计时
        setPhoneVerification((prev) => ({
          ...prev,
          isCodeSent: true,
          countdown: 60
        }))

        // 倒计时逻辑
        const timer = setInterval(() => {
          setPhoneVerification((prev) => {
            if (prev.countdown <= 1) {
              clearInterval(timer)
              return { ...prev, countdown: 0 }
            }
            return { ...prev, countdown: prev.countdown - 1 }
          })
        }, 1000)
      }
    } catch (error) {
      setError(error.response?.data?.message || '發送驗證碼失敗')
    }
  }

  // 驗證碼驗證處理函數
  const handleVerifyCode = async () => {
    try {
      const response = await api.post('/api/auth/verify-code', {
        phone: phoneVerification.phone,
        code: phoneVerification.code
      })

      if (response.data.success) {
        setIsPhoneVerified(true)
        setPhoneRegisterStep(2)
        message.success('驗證成功！請設置密碼')
      }
    } catch (error) {
      setError(error.response?.data?.message || '驗證碼錯誤')
    }
  }

  // 修改手機註冊表單渲染
  const renderMobileRegisterForm = () => (
    <FormMobile onSubmit={handleRegisterSubmit}>
      {error && <ErrorMessageMobile>{error}</ErrorMessageMobile>}

      {/* 註冊方式選擇按鈕 */}
      <div style={{ marginBottom: '15px', display: 'flex', gap: '10px' }}>
        <ButtonMobile
          type='button'
          onClick={() => setRegisterMethod('email')}
          style={{
            flex: 1, // 讓按鈕平均分配空間
            background: registerMethod === 'email' ? '#e14a63' : '#f5f5f5',
            color: registerMethod === 'email' ? 'white' : 'black'
          }}
        >
          信箱註冊
        </ButtonMobile>
        <ButtonMobile
          type='button'
          onClick={() => setRegisterMethod('phone')}
          style={{
            flex: 1, // 讓按鈕平均分配空間
            background: registerMethod === 'phone' ? '#e14a63' : '#f5f5f5',
            color: registerMethod === 'phone' ? 'white' : 'black'
          }}
        >
          手機註冊
        </ButtonMobile>
      </div>

      {registerMethod === 'phone' ? (
        <>
          {phoneRegisterStep === 1 ? (
            // 步驟 1: 手機號驗證
            <>
              <div style={{ position: 'relative', marginBottom: '15px' }}>
                <InputMobile
                  type='tel'
                  placeholder='請輸入手機號碼'
                  value={phoneVerification.phone}
                  onChange={(e) =>
                    setPhoneVerification((prev) => ({
                      ...prev,
                      phone: e.target.value
                    }))
                  }
                />
                {phoneVerification.isCodeSent && (
                  <HintText style={{ color: 'green' }}>
                    驗證碼已發送到您的手機
                  </HintText>
                )}
              </div>

              {phoneVerification.isCodeSent && (
                <div style={{ marginBottom: '15px' }}>
                  <InputMobile
                    type='text'
                    placeholder='請輸入驗證碼'
                    value={phoneVerification.code}
                    onChange={(e) =>
                      setPhoneVerification((prev) => ({
                        ...prev,
                        code: e.target.value
                      }))
                    }
                  />
                </div>
              )}

              <div style={{ display: 'flex', gap: '10px' }}>
                <ButtonMobile
                  type='button'
                  onClick={handleSendVerificationCode}
                  disabled={phoneVerification.countdown > 0}
                  style={{
                    flex: phoneVerification.isCodeSent ? '1' : 'auto',
                    backgroundColor:
                      phoneVerification.countdown > 0 ? '#ccc' : '#4285f4'
                  }}
                >
                  {phoneVerification.countdown > 0
                    ? `${phoneVerification.countdown}秒後重發`
                    : '獲取驗證碼'}
                </ButtonMobile>

                {phoneVerification.isCodeSent && (
                  <ButtonMobile
                    type='button'
                    onClick={handleVerifyCode}
                    style={{ flex: '1', backgroundColor: '#e14a63' }}
                  >
                    驗證
                  </ButtonMobile>
                )}
              </div>
            </>
          ) : (
            // 步驟 2: 設置密碼
            <>
              <div style={{ marginBottom: '20px' }}>
                <InputMobile
                  type='password'
                  placeholder='設置密碼'
                  value={registerData.password}
                  onChange={(e) =>
                    setRegisterData({
                      ...registerData,
                      password: e.target.value
                    })
                  }
                />
                <HintText>
                  密碼至少需要6位，必須包含至少一個字母和一個數字
                </HintText>
              </div>

              <div style={{ marginBottom: '20px' }}>
                <InputMobile
                  type='password'
                  placeholder='確認密碼'
                  value={registerData.confirmPassword}
                  onChange={(e) =>
                    setRegisterData({
                      ...registerData,
                      confirmPassword: e.target.value
                    })
                  }
                />
              </div>

              <ButtonMobile
                type='submit'
                disabled={loading}
                style={{ backgroundColor: '#e14a63', width: '100%' }}
              >
                {loading ? '註冊中...' : '完成註冊'}
              </ButtonMobile>
            </>
          )}
        </>
      ) : (
        // 信箱註冊表單內容
        <>
          <InputMobile
            type='text'
            placeholder='帳號'
            value={registerData.username}
            onChange={(e) =>
              setRegisterData({
                ...registerData,
                username: e.target.value
              })
            }
          />
          <HintText>帳號只能包含英文字母和數字，最多50個字符</HintText>

          <InputMobile
            type='text'
            placeholder='姓名'
            value={registerData.name}
            onChange={(e) =>
              setRegisterData({
                ...registerData,
                name: e.target.value
              })
            }
          />

          <InputMobile
            type='email'
            placeholder='電子信箱'
            value={registerData.email}
            onChange={(e) =>
              setRegisterData({ ...registerData, email: e.target.value })
            }
          />

          <InputMobile
            type='tel'
            placeholder='手機號碼'
            value={registerData.phone}
            onChange={(e) =>
              setRegisterData({
                ...registerData,
                phone: e.target.value
              })
            }
          />
          <HintText>請輸入台灣手機號碼（例：0912345678）</HintText>

          <InputMobile
            type='password'
            placeholder='密碼'
            value={registerData.password}
            onChange={(e) =>
              setRegisterData({ ...registerData, password: e.target.value })
            }
          />
          <HintText>密碼至少需要6位，必須包含至少一個字母和一個數字</HintText>

          <InputMobile
            type='password'
            placeholder='確認密碼'
            value={registerData.confirmPassword}
            onChange={(e) =>
              setRegisterData({
                ...registerData,
                confirmPassword: e.target.value
              })
            }
          />

          <ButtonMobile type='submit' disabled={loading}>
            {loading ? '註冊中...' : '註冊'}
          </ButtonMobile>
        </>
      )}
    </FormMobile>
  )

  // 在 PC 版本中也添加類似的修改
  const renderPCRegisterForm = () => (
    <FormPC onSubmit={handleRegisterSubmit}>
      <TitlePC>新朋友？歡迎加入</TitlePC>
      {error && <ErrorMessagePC>{error}</ErrorMessagePC>}

      <div style={{ marginBottom: '20px' }}>
        <ButtonPC
          type='button'
          onClick={() => setRegisterMethod('email')}
          style={{
            marginRight: '10px',
            background: registerMethod === 'email' ? '#e14a63' : '#f5f5f5',
            color: registerMethod === 'email' ? 'white' : 'black'
          }}
        >
          信箱註冊
        </ButtonPC>
        <ButtonPC
          type='button'
          onClick={() => setRegisterMethod('phone')}
          style={{
            background: registerMethod === 'phone' ? '#e14a63' : '#f5f5f5',
            color: registerMethod === 'phone' ? 'white' : 'black'
          }}
        >
          手機註冊
        </ButtonPC>
      </div>

      {registerMethod === 'phone' ? (
        <>
          {phoneRegisterStep === 1 ? (
            // 步驟 1: 手機號驗證
            <>
              <InputGroupPC style={{ position: 'relative', width: '100%' }}>
                <InputPC
                  type='tel'
                  placeholder='手機號碼'
                  value={phoneVerification.phone}
                  onChange={(e) =>
                    setPhoneVerification((prev) => ({
                      ...prev,
                      phone: e.target.value
                    }))
                  }
                />
                <ButtonMobile
                  type='button'
                  onClick={handleSendVerificationCode}
                  disabled={phoneVerification.countdown > 0}
                  style={{
                    position: 'absolute',
                    right: '0',
                    top: '0',
                    width: '100px',
                    height: '80%',
                    padding: '0',
                    marginTop: '9px',
                    backgroundColor:
                      phoneVerification.countdown > 0 ? '#ccc' : '#4285f4'
                  }}
                >
                  {phoneVerification.countdown > 0
                    ? `${phoneVerification.countdown}秒`
                    : '發送驗證碼'}
                </ButtonMobile>
              </InputGroupPC>

              {phoneVerification.isCodeSent && (
                <>
                  <HintText style={{ color: 'green', textAlign: 'center' }}>
                    驗證碼已發送到您的手機
                  </HintText>
                  <InputGroupPC>
                    <InputPC
                      type='text'
                      placeholder='請輸入驗證碼'
                      value={phoneVerification.code}
                      onChange={(e) =>
                        setPhoneVerification((prev) => ({
                          ...prev,
                          code: e.target.value
                        }))
                      }
                    />
                  </InputGroupPC>
                  <ButtonPC
                    type='button'
                    onClick={handleVerifyCode}
                    style={{ width: '100%', marginTop: '10px' }}
                  >
                    驗證
                  </ButtonPC>
                </>
              )}
            </>
          ) : (
            // 步驟 2: 設置密碼
            <>
              <InputGroupPC>
                <InputPC
                  type='password'
                  placeholder='設置密碼'
                  value={registerData.password}
                  onChange={(e) =>
                    setRegisterData({
                      ...registerData,
                      password: e.target.value
                    })
                  }
                />
                <HintText>
                  密碼至少需要6位，必須包含至少一個字母和一個數字
                </HintText>
              </InputGroupPC>

              <InputGroupPC>
                <InputPC
                  type='password'
                  placeholder='確認密碼'
                  value={registerData.confirmPassword}
                  onChange={(e) =>
                    setRegisterData({
                      ...registerData,
                      confirmPassword: e.target.value
                    })
                  }
                />
                <HintText>請再次輸入密碼以確認</HintText>
              </InputGroupPC>

              <ButtonPC
                type='primary'
                htmlType='submit'
                disabled={loading}
                style={{ width: '100%' }}
                onClick={(e) => {
                  e.preventDefault()
                  handleRegisterSubmit(e)
                }}
              >
                {loading ? '註冊中...' : '完成註冊'}
              </ButtonPC>
            </>
          )}
        </>
      ) : (
        // 信箱註冊表單內容
        <>
          <InputPC
            type='text'
            placeholder='帳號'
            value={registerData.username}
            onChange={(e) =>
              setRegisterData({
                ...registerData,
                username: e.target.value
              })
            }
          />
          <HintText>帳號只能包含英文字母和數字，最多50個字符</HintText>

          <InputPC
            type='text'
            placeholder='姓名'
            value={registerData.name}
            onChange={(e) =>
              setRegisterData({
                ...registerData,
                name: e.target.value
              })
            }
          />

          <InputPC
            type='email'
            placeholder='電子信箱'
            value={registerData.email}
            onChange={(e) =>
              setRegisterData({ ...registerData, email: e.target.value })
            }
          />

          <InputPC
            type='tel'
            placeholder='手機號碼'
            value={registerData.phone}
            onChange={(e) =>
              setRegisterData({
                ...registerData,
                phone: e.target.value
              })
            }
          />
          <HintText>請輸入台灣手機號碼（例：0912345678）</HintText>

          <InputPC
            type='password'
            placeholder='密碼'
            value={registerData.password}
            onChange={(e) =>
              setRegisterData({ ...registerData, password: e.target.value })
            }
          />
          <HintText>密碼至少需要6位，必須包含至少一個字母和一個數字</HintText>

          <InputPC
            type='password'
            placeholder='確認密碼'
            value={registerData.confirmPassword}
            onChange={(e) =>
              setRegisterData({
                ...registerData,
                confirmPassword: e.target.value
              })
            }
          />

          <ButtonPC
            type='submit'
            disabled={loading}
            onClick={(e) => {
              e.preventDefault()
              handleRegisterSubmit(e)
            }}
          >
            {loading ? '註冊中...' : '註冊'}
          </ButtonPC>
        </>
      )}
    </FormPC>
  )

  if (isMobile) {
    return (
      <ContainerMobile>
        <Helmet>
          <title>{`線上預約會員登入 - 美感｜BeauteFeel`}</title>
          <meta
            name='description'
            content={`全臺美容線上預約平臺，不論美甲美容美髮美睫霧唇霧眉，輕鬆一鍵查詢並完成預約，找到最符合您需求的美容專家!`}
          />
        </Helmet>
        <BoxMobile>
          <TitleMobile>
            {isMobileSignUp ? '新朋友？歡迎加入' : '歡迎回來'}
          </TitleMobile>
          {isMobileSignUp ? (
            renderMobileRegisterForm()
          ) : (
            <FormMobile onSubmit={handleLoginSubmit}>
              {error && <ErrorMessageMobile>{error}</ErrorMessageMobile>}
              <InputMobile
                type='text'
                placeholder='帳號'
                value={loginData.username}
                onChange={(e) =>
                  setLoginData({ ...loginData, username: e.target.value })
                }
              />
              <InputMobile
                type='password'
                placeholder='密碼'
                value={loginData.password}
                onChange={(e) =>
                  setLoginData({ ...loginData, password: e.target.value })
                }
              />
              <ButtonMobile type='submit' disabled={loading}>
                {loading ? '登入中...' : '登入'}
              </ButtonMobile>
            </FormMobile>
          )}
          <Divider>快速登入</Divider>
          <SocialLoginContainer>
            <GoogleButton onClick={handleGoogleLogin}>
              <FaGoogle />
            </GoogleButton>
            {/* <FacebookButton onClick={handleFacebookLogin}>
              <FaFacebook />
            </FacebookButton> */}
            <LineButton onClick={handleLineLogin}>
              <FaLine />
            </LineButton>
          </SocialLoginContainer>
          <Text style={{ textAlign: 'center', marginTop: '20px' }}>
            {isMobileSignUp ? '已有帳號？' : '還沒有帳號？'}{' '}
            <button
              onClick={toggleMobileAuthMode}
              style={{
                background: 'none',
                border: 'none',
                color: '#1890ff',
                cursor: 'pointer',
                padding: 0,
                font: 'inherit',
                textDecoration: 'underline'
              }}
            >
              {isMobileSignUp ? '登入' : '註冊'}
            </button>
          </Text>
        </BoxMobile>
      </ContainerMobile>
    )
  }

  return (
    <ContainerPC>
      <Helmet>
        <title>{`會員登入註冊 ｜ 全臺線上即時預約平臺優惠推薦 - 美感｜BeauteFeel`}</title>
        <meta
          name='description'
          content={`全臺美容線上預約平臺，不論美甲美容美髮美睫霧唇霧眉，輕鬆一鍵查詢並完成預約，找到最符合您需求的美容專家!`}
        />
      </Helmet>
      <BoxPC>
        <PanelPC $show={!isSignUp}>
          <FormPC onSubmit={handleLoginSubmit}>
            <TitlePC>登入</TitlePC>
            {error && <ErrorMessagePC>{error}</ErrorMessagePC>}
            <InputGroupPC>
              <InputPC
                type='text'
                placeholder='帳號'
                value={loginData.username}
                onChange={(e) =>
                  setLoginData({ ...loginData, username: e.target.value })
                }
              />
            </InputGroupPC>
            <InputGroupPC>
              <InputPC
                type='password'
                placeholder='密碼'
                value={loginData.password}
                onChange={(e) =>
                  setLoginData({ ...loginData, password: e.target.value })
                }
              />
            </InputGroupPC>
            <ButtonPC type='primary' htmlType='submit' disabled={loading}>
              {loading ? 'Loading...' : '登入'}
            </ButtonPC>
            <div style={{ marginTop: '20px' }}></div>
            <Divider>快速登入</Divider>
            <SocialLoginContainer>
              <GoogleButton onClick={handleGoogleLogin}>
                <FaGoogle />
              </GoogleButton>
              {/* <FacebookButton onClick={handleFacebookLogin}>
                <FaFacebook />
              </FacebookButton> */}
              <LineButton onClick={handleLineLogin}>
                <FaLine />
              </LineButton>
            </SocialLoginContainer>
          </FormPC>
        </PanelPC>
        <PanelRightPC $show={isSignUp}>{renderPCRegisterForm()}</PanelRightPC>
        <OverlayPanelPC $isSignUp={isSignUp}>
          <OverlayPanelContentPC>
            <h3>美感 BeauteFeel</h3>
            <TitlePC style={{ color: 'white' }}>
              {isSignUp ? '歡迎回來！老朋友' : '新朋友？歡迎加入'}
            </TitlePC>
            <p>
              {isSignUp
                ? '請使用您的個人信息登入以保持聯繫'
                : '請使用您的電子信箱進行註冊'}
            </p>
            <ButtonPC onClick={toggleAuthMode} disabled={loading}>
              {isSignUp ? '登入' : '註冊'}
            </ButtonPC>
          </OverlayPanelContentPC>
        </OverlayPanelPC>
      </BoxPC>
    </ContainerPC>
  )
}

export default LoginPage
