import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { DeleteOutlined } from '@ant-design/icons'
import { Toast } from 'antd-mobile'
import { useAuth } from '../hooks/AuthContext'
import api from '../utils/api'
import { PHOTO_URL } from '../utils/apiutils'
import Loading from '../utils/Loading'
import CheckoutModal from '../components/CheckoutModal'

const CartContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  text-align: left !important;

  @media (max-width: 820px) {
    padding: 10px;
  }
`

const LoginPrompt = styled.div`
  background: #e6f0ff;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;

  a {
    color: #d5728a;
    text-decoration: none;
    margin: 0 5px;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 820px) {
    margin-top: 10px;
  }
`

const CartItem = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 20px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  margin-bottom: 10px;
  align-items: center;
  position: relative;

  @media (max-width: 820px) {
    grid-template-columns: auto 1fr;
    padding: 15px;
    padding-right: 40px;
  }
`

const ItemImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  background-color: #f5f5f5;
  border-radius: 4px;
  cursor: pointer;

  @media (max-width: 820px) {
    width: 80px;
    height: 80px;
  }
`

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const ItemTitle = styled.h3`
  margin: 0;
  font-size: 16px;
  color: #333;
  cursor: pointer;
`

const ItemPrice = styled.div`
  color: #d5728a;
  font-weight: bold;
  font-size: 18px;

  span {
    color: #999;
    text-decoration: line-through;
    margin-left: 8px;
    font-weight: normal;
    font-size: 14px;
  }
`

const QuantityControl = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  button {
    width: 28px;
    height: 28px;
    border: 1px solid #ddd;
    background: white;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;

    &:hover {
      background: #f5f5f5;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  span {
    min-width: 30px;
    text-align: center;
  }
`

const CartSummary = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;

  h3 {
    margin: 0;
    color: #333;
  }

  p {
    font-size: 24px;
    color: #d5728a;
    font-weight: bold;
    margin: 10px 0;
  }
`

const CheckoutButton = styled.button`
  width: 100%;
  padding: 15px;
  background: #d5728a;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  transition: background 0.3s ease;

  &:hover {
    background: #bf5170;
  }
`

const DeleteButton = styled(DeleteOutlined)`
  cursor: pointer;
  font-size: 20px;
  color: #999;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #d5728a;
  }

  @media (max-width: 820px) {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 24px;
    height: 24px;
    padding: 4px;
  }
`

const EmptyCart = styled.div`
  text-align: center;
  padding: 40px 0;

  h2 {
    color: #333;
    margin-bottom: 20px;
  }

  button {
    padding: 12px 24px;
    background: #d5728a;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s ease;

    &:hover {
      background: #bf5170;
    }
  }
`

const CouponSection = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
`

const CouponHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;

  h3 {
    margin: 0;
    color: #333;
  }

  span {
    color: #666;
    font-size: 14px;
  }
`

const CouponInput = styled.div`
  display: flex;
  gap: 10px;

  input {
    flex: 1;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.3s ease;

    &:focus {
      border-color: #d5728a;
    }
  }

  button {
    padding: 0 20px;
    background: #d5728a;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    transition: background 0.3s ease;

    &:hover {
      background: #bf5170;
    }

    &:disabled {
      background: #ccc;
      cursor: not-allowed;
    }
  }
`

const AppliedCoupon = styled.div`
  margin-top: 15px;
  padding: 12px;
  background: #f8f8f8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .coupon-info {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .coupon-code {
      font-weight: bold;
      color: #d5728a;
    }

    .coupon-desc {
      font-size: 12px;
      color: #666;
    }
  }

  .remove-coupon {
    color: #999;
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      color: #d5728a;
    }
  }
`

const DiscountInfo = styled.div`
  display: flex;
  justify-content: space-between;
  color: #d5728a;
  font-size: 14px;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px dashed #eee;
`

const StoreSection = styled.div`
  background: white;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 20px;
`

const StoreHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  h3 {
    margin: 0;
    color: #333;
  }
`

const ShippingInfo = styled.div`
  color: #d5728a;
  font-size: 14px;
`

const StoreSubtotal = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #eee;
  color: #666;
`

const SpecificationInfo = styled.div`
  font-size: 12px;
  color: #666;
  margin-top: 4px;
`

const InvalidItemsAlert = styled.div`
  background: #fff3f3;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;

  h4 {
    color: #d5728a;
    margin: 0 0 10px 0;
  }
`

const Cart = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const [cartData, setCartData] = useState({
    stores: {},
    items: [],
    total: 0,
    invalidItems: []
  })
  const [loading, setLoading] = useState(true)
  const [couponCode, setCouponCode] = useState('')
  const [appliedCoupon, setAppliedCoupon] = useState(null)
  const [isApplying, setIsApplying] = useState(false)

  // 合併購物車的邏輯
  const mergeCarts = async () => {
    try {
      // 1. 檢查本地購物車
      const localCart = JSON.parse(localStorage.getItem('cart') || '[]')
      if (localCart.length === 0) return

      setLoading(true)

      // 2. 獲取服務器購物車
      const response = await api.get('/api/client/store/cart')

      // 3. 確保 serverCart 是一個數組
      const serverCart = Array.isArray(response.data.data?.items)
        ? response.data.data.items
        : []

      // 4. 如果服務器購物車不為空，則不合併
      if (serverCart.length > 0) {
        setLoading(false)
        return
      }

      // 5. 合併購物車項目
      for (const item of localCart) {
        try {
          await api.put('/api/client/store/cart', {
            action: 'add',
            productId: item.productId,
            quantity: item.quantity,
            specifications: item.specifications || {},
            businessId: item.businessId
          })
        } catch (itemError) {
          console.error('添加商品失敗:', item, itemError)
        }
      }

      // 6. 清空本地購物車
      localStorage.removeItem('cart')

      // 7. 重新獲取最新的購物車內容
      const updatedResponse = await api.get('/api/client/store/cart')
      setCartData(updatedResponse.data.data)

      Toast.show({
        icon: 'success',
        content: '購物車已同步',
        position: 'center'
      })
    } catch (error) {
      console.error('合併購物車失敗:', error)
      // 輸出更詳細的錯誤信息
      if (error.response) {
        console.error('錯誤響應:', error.response.data)
        console.error('錯誤狀態:', error.response.status)
      }
      Toast.show({
        icon: 'fail',
        content: '同步購物車失敗',
        position: 'center'
      })
    } finally {
      setLoading(false)
    }
  }

  // 監聽用戶登入狀態
  useEffect(() => {
    const handleUserLogin = async () => {
      if (user) {
        await mergeCarts()
      }
    }

    handleUserLogin()
  }, [user])

  // 修改獲取購物車內容的部分
  useEffect(() => {
    const fetchCart = async () => {
      setLoading(true)
      try {
        if (user) {
          const response = await api.get('/api/client/store/cart')
          setCartData(response.data.data)
        } else {
          const localCart = JSON.parse(localStorage.getItem('cart') || '[]')
          // 本地購物車數據結構轉換
          const groupedByStore = localCart.reduce((acc, item) => {
            const businessId = item.businessId || 'default'
            if (!acc[businessId]) {
              acc[businessId] = {
                storeName: item.storeName || '商店',
                items: [],
                subtotal: 0,
                shippingFee: 0,
                freeShippingThreshold: 0
              }
            }
            acc[businessId].items.push(item)
            acc[businessId].subtotal += item.price * item.quantity
            return acc
          }, {})

          const localCartData = {
            stores: groupedByStore,
            total: localCart.reduce(
              (sum, item) => sum + item.price * item.quantity,
              0
            ),
            invalidItems: [],
            updatedAt: new Date()
          }
          setCartData(localCartData)
        }
      } catch (error) {
        console.error('獲取購物車失敗:', error)
        Toast.show({
          icon: 'fail',
          content: '獲取購物車失敗',
          position: 'center'
        })
      } finally {
        setLoading(false)
      }
    }

    fetchCart()
  }, [user])

  // 修改更新購物車數量的函數
  const handleQuantityChange = async (businessId, itemId, newQuantity) => {
    if (newQuantity < 1) return

    try {
      if (user) {
        const item = cartData.stores[businessId].items.find(
          (item) => item.productId === itemId
        )
        await api.put('/api/client/store/cart', {
          action: 'update',
          businessId,
          productId: itemId,
          quantity: newQuantity,
          specifications: item.specifications
        })
      } else {
        const localCart = JSON.parse(localStorage.getItem('cart') || '[]')
        const updatedCart = localCart.map((item) =>
          item.productId === itemId ? { ...item, quantity: newQuantity } : item
        )
        localStorage.setItem('cart', JSON.stringify(updatedCart))
      }

      // 更新本地狀態
      setCartData((prevData) => ({
        ...prevData,
        stores: {
          ...prevData.stores,
          [businessId]: {
            ...prevData.stores[businessId],
            items: prevData.stores[businessId].items.map((item) =>
              item.productId === itemId
                ? { ...item, quantity: newQuantity }
                : item
            ),
            subtotal: prevData.stores[businessId].items.reduce((sum, item) => {
              if (item.productId === itemId) {
                return sum + item.price * newQuantity
              }
              return sum + item.price * item.quantity
            }, 0)
          }
        },
        total: Object.values(prevData.stores).reduce((total, store) => {
          return (
            total +
            store.items.reduce((storeTotal, item) => {
              if (item.productId === itemId) {
                return storeTotal + item.price * newQuantity
              }
              return storeTotal + item.price * item.quantity
            }, 0)
          )
        }, 0)
      }))

      // 觸發購物車更新事件
      window.dispatchEvent(new CustomEvent('cartUpdated'))

      Toast.show({
        icon: 'success',
        content: '數量已更新',
        position: 'center'
      })
    } catch (error) {
      console.error('更新數量失敗:', error)
      Toast.show({
        icon: 'fail',
        content: '更新失敗',
        position: 'center'
      })
    }
  }

  // 修改移除購物車商品函數
  const handleRemoveItem = async (businessId, productId) => {
    try {
      if (user) {
        await api.put('/api/client/store/cart', {
          action: 'remove',
          businessId,
          productId
        })

        // 重新獲取購物車內容
        const response = await api.get('/api/client/store/cart')
        setCartData(response.data.data)
      } else {
        // 未登錄用戶更新 localStorage
        const localCart = JSON.parse(localStorage.getItem('cart') || '[]')
        const updatedCart = localCart.filter(
          (item) => item.productId !== productId
        )
        localStorage.setItem('cart', JSON.stringify(updatedCart))

        // 重新計算並更新整個購物車狀態
        const groupedByStore = updatedCart.reduce((acc, item) => {
          const businessId = item.businessId || 'default'
          if (!acc[businessId]) {
            acc[businessId] = {
              storeName: item.storeName || '商店',
              items: [],
              subtotal: 0,
              shippingFee: 0,
              freeShippingThreshold: 0
            }
          }
          acc[businessId].items.push(item)
          acc[businessId].subtotal += item.price * item.quantity
          return acc
        }, {})

        setCartData({
          stores: groupedByStore,
          total: updatedCart.reduce(
            (sum, item) => sum + item.price * item.quantity,
            0
          ),
          invalidItems: [],
          updatedAt: new Date()
        })
      }

      Toast.show({
        icon: 'success',
        content: '商品已移除',
        position: 'center'
      })
    } catch (error) {
      console.error('移除商品失敗:', error)
      Toast.show({
        icon: 'fail',
        content: '移除失敗',
        position: 'center'
      })
    }
  }

  const calculateTotal = () => {
    return cartData.total
  }

  const handleApplyCoupon = async () => {
    if (!couponCode.trim()) return

    setIsApplying(true)
    try {
      // 模擬 API 調用
      await new Promise((resolve) => setTimeout(resolve, 500))

      // 模擬優惠券驗證
      const mockCoupon = {
        code: couponCode.toUpperCase(),
        discount: 100,
        description: '新用戶優惠'
      }

      setAppliedCoupon(mockCoupon)
      setCouponCode('')
      Toast.show({
        icon: 'success',
        content: '優惠券已套用',
        position: 'center'
      })
    } catch (error) {
      Toast.show({
        icon: 'fail',
        content: '優惠券無效',
        position: 'center'
      })
    } finally {
      setIsApplying(false)
    }
  }

  const removeCoupon = () => {
    setAppliedCoupon(null)
    Toast.show({
      content: '已移除優惠券',
      position: 'center'
    })
  }

  const handleCheckout = () => {
    if (user) {
      navigate('/cart/checkout/payment')
    } else {
      // 如果用戶未登入，先導向登入頁面
      navigate('/login', { state: { from: '/cart' } })
    }
  }

  const handleCloseCheckout = () => {
    navigate('/cart')
  }

  // 修改判断空购物车的逻辑
  const isCartEmpty = () => {
    return !cartData.stores || Object.keys(cartData.stores).length === 0
  }

  // 判斷是否顯示結帳 modal
  const isCheckoutModalOpen = location.pathname.includes('/cart/checkout')

  if (loading) {
    return (
      <CartContainer>
        <Loading />
      </CartContainer>
    )
  }

  // 使用新的判断空购物车的方法
  if (isCartEmpty()) {
    return (
      <CartContainer>
        {!user && (
          <LoginPrompt>
            已經是會員？<Link to='/login'>請登入</Link>，可方便您管理訂單。
            不是會員？<Link to='/register'>立即註冊</Link>，下次即可快速結帳！
          </LoginPrompt>
        )}
        <EmptyCart>
          <h2>購物車是空的</h2>
          <button onClick={() => navigate('/')}>繼續購物</button>
        </EmptyCart>
      </CartContainer>
    )
  }

  return (
    <CartContainer>
      {!user && (
        <LoginPrompt>
          已經是會員？<Link to='/login'>請登入</Link>，可方便您管理訂單。
          不是會員？<Link to='/register'>立即註冊</Link>，下次即可快速結帳！
        </LoginPrompt>
      )}

      {/* 顯示無效商品提示 */}
      {cartData.invalidItems && cartData.invalidItems.length > 0 && (
        <InvalidItemsAlert>
          <h4>以下商品已無法購買：</h4>
          {cartData.invalidItems.map((item) => (
            <div key={item.productId}>{item.title}</div>
          ))}
        </InvalidItemsAlert>
      )}

      {/* 按商店分組顯示購物車商品 */}
      {Object.entries(cartData.stores).map(([businessId, storeData]) => (
        <StoreSection key={businessId}>
          <StoreHeader>
            <h3>{storeData.storeName}</h3>
            {storeData.freeShippingThreshold > 0 && (
              <ShippingInfo>
                {storeData.subtotal >= storeData.freeShippingThreshold ? (
                  <span>已達免運門檻</span>
                ) : (
                  <span>
                    還差 NT${' '}
                    {(
                      storeData.freeShippingThreshold - storeData.subtotal
                    ).toLocaleString()}{' '}
                    享免運
                  </span>
                )}
              </ShippingInfo>
            )}
          </StoreHeader>

          {storeData.items.map((item) => (
            <CartItem key={item.productId}>
              <ItemImage
                src={PHOTO_URL + item.image}
                alt={item.title}
                onClick={() =>
                  navigate(`/product/${businessId}/${item.productId}`)
                }
              />
              <ItemInfo>
                <ItemTitle
                  onClick={() =>
                    navigate(`/product/${businessId}/${item.productId}`)
                  }
                >
                  {item.title}
                </ItemTitle>
                <ItemPrice>
                  NT$ {item.price.toLocaleString()}
                  {item.originalPrice && (
                    <span>NT$ {item.originalPrice.toLocaleString()}</span>
                  )}
                </ItemPrice>
                {item.specifications?.map((spec) => (
                  <SpecificationInfo key={spec.name}>
                    {spec.name}: {spec.selected}
                  </SpecificationInfo>
                ))}
                <QuantityControl>
                  <button
                    onClick={() =>
                      handleQuantityChange(
                        businessId,
                        item.productId,
                        item.quantity - 1
                      )
                    }
                    disabled={item.quantity <= 1}
                  >
                    -
                  </button>
                  <span>{item.quantity}</span>
                  <button
                    onClick={() =>
                      handleQuantityChange(
                        businessId,
                        item.productId,
                        item.quantity + 1
                      )
                    }
                  >
                    +
                  </button>
                </QuantityControl>
              </ItemInfo>
              <DeleteButton
                onClick={() => handleRemoveItem(businessId, item.productId)}
                aria-label='移除商品'
              />
            </CartItem>
          ))}

          <StoreSubtotal>
            <div>小計</div>
            <div>NT$ {storeData.subtotal.toLocaleString()}</div>
            {storeData.shippingFee > 0 && (
              <div>運費: NT$ {storeData.shippingFee.toLocaleString()}</div>
            )}
          </StoreSubtotal>
        </StoreSection>
      ))}

      {/* 購物車總計 */}
      <CartSummary>
        <div>
          <h3>總計</h3>
          <p>NT$ {cartData.total.toLocaleString()}</p>
        </div>
        <CheckoutButton
          onClick={handleCheckout}
          disabled={cartData.invalidItems && cartData.invalidItems.length > 0}
        >
          立即結帳
        </CheckoutButton>
      </CartSummary>

      {/* 結帳 Modal */}
      {isCheckoutModalOpen && (
        <CheckoutModal
          isOpen={true}
          onClose={handleCloseCheckout}
          total={calculateTotal() - (appliedCoupon?.discount || 0)}
          cartData={cartData}
          currentPath={location.pathname}
        />
      )}
    </CartContainer>
  )
}

export default Cart
