import React, { createContext, useContext, useState } from 'react'

const SuccessContext = createContext()

export const SuccessProvider = ({ children }) => {
  const [successStates, setSuccessStates] = useState(() => {
    // 從 sessionStorage 讀取初始狀態
    const savedStates = sessionStorage.getItem('successStates')
    return savedStates
      ? JSON.parse(savedStates)
      : {
          review: false,
          booking: false
        }
  })

  const markSuccess = (type) => {
    console.log(`Marking ${type} as success`)
    setSuccessStates((prev) => {
      const newStates = {
        ...prev,
        [type]: true
      }
      // 保存到 sessionStorage
      sessionStorage.setItem('successStates', JSON.stringify(newStates))
      return newStates
    })
  }

  const resetSuccess = (type) => {
    console.log(`Resetting ${type} success state`)
    setSuccessStates((prev) => {
      const newStates = {
        ...prev,
        [type]: false
      }
      // 更新 sessionStorage
      sessionStorage.setItem('successStates', JSON.stringify(newStates))
      return newStates
    })
  }

  const resetAllSuccess = () => {
    setSuccessStates({
      review: false,
      booking: false
    })
  }

  return (
    <SuccessContext.Provider
      value={{
        successStates,
        markSuccess,
        resetSuccess,
        resetAllSuccess
      }}
    >
      {children}
    </SuccessContext.Provider>
  )
}

export const useSuccess = () => {
  const context = useContext(SuccessContext)
  if (!context) {
    throw new Error('useSuccess must be used within a SuccessProvider')
  }
  return context
}
