import React from 'react'
import { ConfigProvider } from 'antd'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import { BrowserRouter as Router } from 'react-router-dom'
import AppRouter from './router/AppRouter'
import './App.css'
import { AuthProvider } from './hooks/AuthContext'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import zh_TW from 'antd/es/locale/zh_TW'
import { SuccessProvider } from './context/SuccessContext'

dayjs.extend(utc)
dayjs.extend(timezone)

// 設置默認時區為台灣時間
dayjs.tz.setDefault('Asia/Taipei')

const App = () => {
  return (
    <HelmetProvider>
      <div className='App'>
        <AuthProvider>
          <SuccessProvider>
            <Router>
              <ConfigProvider
                locale={zh_TW}
                theme={{
                  token: {
                    colorPrimary: '#d5728a',
                    colorPrimaryBorder: '#F2B3C0'
                  }
                }}
              >
                <Helmet>
                  <title>美感｜BeauteFeel-全台美業設計師線上預約平台</title>
                  <meta
                    name='description'
                    content='24h全方位美容平台，美業預約不間斷，美髮、美睫、美甲、美容、護膚、SPA線上預約系統'
                  />
                </Helmet>
                <AppRouter />
              </ConfigProvider>
            </Router>
          </SuccessProvider>
        </AuthProvider>
      </div>
    </HelmetProvider>
  )
}

export default App
