import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  Button,
  Input,
  Form,
  Select,
  Drawer,
  message,
  Switch,
  Flex,
  Checkbox
} from 'antd'
import { TagOutlined, CopyOutlined } from '@ant-design/icons'
import { isMobile } from 'react-device-detect'
import { Toast } from 'antd-mobile'
import { useAuth } from '../hooks/AuthContext'
import ProtectedRoute from '../router/ProtectRoute'
import { useParams } from 'react-router-dom'
import api from '../utils/api'
import Loading from '../utils/Loading'

const Container = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

const Title = styled.h2`
  margin-bottom: 20px;
  color: #333;
`

const FormItem = styled(Form.Item)`
  margin-bottom: 20px;
`

const InputGroup = styled.div`
  display: flex;
  gap: 10px;
`

const CouponItem = styled.div`
  background-color: white;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CouponInfo = styled.div`
  flex: 1;
`

const CouponCode = styled.div`
  font-weight: bold;
  color: #1890ff;
`

const CouponDescription = styled.div`
  font-size: 12px;
  color: #888;
`

// 添加樣式
const ExtraText = styled.div`
  text-align: left;
  color: #666;
  font-size: 12px;
`

const BookingStepThree = ({
  prevStep,
  onConfirm,
  onCancel,
  businessId,
  depositInfo,
  availableCoupons = [],
  onCouponChange,
  onClaimCoupon,
  validateAndApplyCoupon
}) => {
  const [form] = Form.useForm()
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [hasTransferred, setHasTransferred] = useState(false)
  const [depositConfirmed, setDepositConfirmed] = useState(false)
  const [selectedCoupon, setSelectedCoupon] = useState(null)
  const [couponCode, setCouponCode] = useState('')
  const { user } = useAuth()
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [joinLine, setJoinLine] = useState(false)
  const [visitCount, setVisitCount] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showEmailWarning, setShowEmailWarning] = useState(!user?.email)

  useEffect(() => {
    form.setFieldsValue({
      name: user?.name || '',
      email: user?.email || '',
      phone: user?.phone || ''
    })
  }, [user, form])

  // 獲取訪問次數
  useEffect(() => {
    const fetchVisitCount = async () => {
      try {
        setIsLoading(true)
        const response = await api.get(
          `/api/client/bookings/visit-count/${businessId}`
        )
        setVisitCount(response.data.visitCount)
      } catch (error) {
        console.error('獲取訪問次數失敗:', error)
        message.error('獲取訪問記錄失敗')
      } finally {
        setIsLoading(false)
      }
    }

    if (businessId) {
      fetchVisitCount()
    }
    console.log(visitCount)
  }, [businessId])

  const renderCouponOptions = () => {
    return (
      <>
        <Select.Option value='none'>不使用優惠券</Select.Option>
        {Array.isArray(availableCoupons) &&
          availableCoupons.map((coupon, index) => {
            if (!coupon) return null
            const couponId = coupon._id || `coupon-${index}`
            return (
              <Select.Option key={couponId} value={couponId}>
                {coupon.code || '無代碼'} - {coupon.description || '無描述'}
              </Select.Option>
            )
          })}
      </>
    )
  }

  const handleCouponSelect = (couponId) => {
    if (couponId === 'none') {
      setSelectedCoupon(null)
      onCouponChange('', null)
    } else {
      const coupon = availableCoupons.find(
        (c) =>
          c &&
          (c._id === couponId ||
            `coupon-${availableCoupons.indexOf(c)}` === couponId)
      )
      if (coupon) {
        setSelectedCoupon(coupon)
        onCouponChange(coupon.code || '', couponId)
        // 檢查 validateAndApplyCoupon 是否存在並且是一個函數
        if (typeof validateAndApplyCoupon === 'function') {
          validateAndApplyCoupon(couponId)
        } else {
          console.error('validateAndApplyCoupon is not a function')
        }
        message.success(`已選擇優惠券: ${coupon.code || '無代碼'}`)
      }
    }
  }

  const handleClaimCoupon = () => {
    if (!couponCode.trim()) {
      message.error('請輸入有效的優惠券代碼')
      return
    }

    onClaimCoupon(couponCode, (success, msg, newCoupon) => {
      if (success) {
        setSelectedCoupon(newCoupon)
        setCouponCode('')
        message.success(msg)
        // 不需要在這裡調用 validateAndApplyCoupon，因為它已經在 handleClaimCoupon 中被調用了
      } else {
        message.error(msg)
      }
    })
  }

  const handleFinish = (values) => {
    const submissionData = {
      ...values,
      depositConfirmed: depositConfirmed,
      depositBankNumber: depositInfo.isEnabled
        ? values.depositBankNumber
        : undefined,
      joinLine
    }

    // 檢查優惠券狀態
    if (selectedCoupon && selectedCoupon._id !== 'none') {
      submissionData.couponCode = selectedCoupon.code
      submissionData.couponId = selectedCoupon._id
    } else {
      // 如果沒有選擇優惠券或選擇了"不使用優惠券"，則不包含優惠券相關字段
      delete submissionData.couponCode
      delete submissionData.couponId
    }

    onConfirm(submissionData)
    setIsConfirmed(true)
    // 在移動設備上滾動到預訂詳情
    if (window.innerWidth <= 820) {
      const summaryElement = document.querySelector('.booking-summary')
      if (summaryElement) {
        summaryElement.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  // 添加表單驗證失敗的處理函數
  const handleFinishFailed = (errorInfo) => {
    // 找到第一個錯誤的欄位
    const firstError = errorInfo.errorFields[0]

    // 找到對應的表單項目元素
    const errorElement = document.querySelector(
      `[name="${firstError.name[0]}"]`
    )
    if (errorElement) {
      // 滾動到��誤欄位的位置，並留出一些上方空間
      errorElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    } else {
      // 如果找不到特定欄位，則滾動到表單頂部
      const formElement = document.querySelector('.booking-form-container')
      if (formElement) {
        formElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    }
  }

  const handleCancel = () => {
    setIsConfirmed(false)
    onCancel() // 通知 BookingService 取消確認
    Toast.show({
      content: '已取消確認內容',
      position: 'top'
    })
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      message.success('帳號已複製成功')
    })
  }

  const clearCoupon = () => {
    setSelectedCoupon(null)
    onCouponChange('', null)
    message.success('已清除優惠券選擇')
  }

  // 新增訪問次數判斷函數
  const shouldShowDeposit = (depositInfo, visitCount) => {
    if (!depositInfo?.isEnabled) return false

    switch (depositInfo.depositCondition) {
      case 'all':
        return true
      case 'newCustomer':
        return visitCount === 0
      case 'visitedOnce':
        return visitCount <= 1
      case 'visitedTwice':
        return visitCount <= 2
      case 'visitedThrice':
        return visitCount <= 3
      default:
        return false
    }
  }

  // 修改台灣手機號碼驗證規則
  const validateTaiwanPhone = (_, value) => {
    if (!value) {
      return Promise.reject('請輸入您的聯繫電話（格式：09xxxxxxxx）')
    }
    const phoneRegex = /^09\d{8}$/
    if (!phoneRegex.test(value)) {
      return Promise.reject('請輸入您的聯繫電話（格式：09xxxxxxxx）')
    }
    return Promise.resolve()
  }

  // 監聽 email 欄位變化
  const handleEmailChange = (e) => {
    setShowEmailWarning(!e.target.value)
  }

  return (
    <ProtectedRoute>
      <Container>
        <Title>確認您的預約內容</Title>
        {isLoading ? (
          <Loading />
        ) : (
          <Form
            className='booking-form-container'
            form={form}
            layout='vertical'
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            key={isConfirmed ? 'confirmed' : 'notConfirmed'}
            initialValues={{
              name: user?.name || '',
              email: user?.email || '',
              phone: user?.phone || ''
            }}
          >
            <FormItem
              name='name'
              label='預訂姓名'
              rules={[{ required: true, message: '請輸入您的名字' }]}
            >
              <Input
                size='large'
                placeholder='輸入姓名'
                disabled={isConfirmed}
              />
            </FormItem>

            <FormItem
              name='email'
              label='電子信箱'
              rules={[
                { 
                  required: true,
                  message: '請輸入您的電子信箱，以便接收預約通知' 
                },
                {
                  type: 'email',
                  message: '請輸入正確Email格式',
                  validateTrigger: ['onChange', 'onBlur']
                }
              ]}
              extra={
                user?.loginType === 'phone' && (
                  <ExtraText>
                    設置電子信箱以接收預約通知及取消通知
                    {showEmailWarning && (
                      <div style={{ color: '#ff4d4f', marginTop: '4px' }}>
                        ⚠️ 未設置電子信箱將無法收到預約相關通知
                      </div>
                    )}
                  </ExtraText>
                )
              }
            >
              <Input 
                placeholder='輸入電子信箱以接收預約通知' 
                size='large' 
                disabled={user?.loginType !== 'phone' || isConfirmed}
                onChange={handleEmailChange}
              />
            </FormItem>

            <FormItem
              name='phone'
              label='聯繫電話'
              rules={[{ validator: validateTaiwanPhone }]}
              validateTrigger={['onChange', 'onBlur']}
              extra={
                user?.loginType === 'phone' && (
                  <ExtraText>此為您的登入手機號碼，不可修改</ExtraText>
                )
              }
            >
              <Input
                size='large'
                placeholder='0912345678'
                disabled={user?.loginType === 'phone' || isConfirmed}
                maxLength={10}
              />
            </FormItem>

            <FormItem
              name='note'
              label='備註事項'
              rules={[{ max: 100, message: '備注不能超過100字' }]}
            >
              <Input.TextArea
                placeholder='請輸入備註事項（選填，最多100字）'
                disabled={isConfirmed}
                size='large'
                maxLength={100}
                showCount
              />
            </FormItem>

            <FormItem label='優惠券'>
              {isMobile ? (
                <>
                  <Button
                    onClick={() => setDrawerVisible(true)}
                    icon={<TagOutlined />}
                  >
                    選擇優惠券
                  </Button>
                  {selectedCoupon && (
                    <div style={{ marginTop: '10px' }}>
                      已選擇: {selectedCoupon.code} -{' '}
                      {selectedCoupon.description}
                      <Button type='link' onClick={clearCoupon}>
                        清除
                      </Button>
                    </div>
                  )}
                  <Drawer
                    title='選擇優惠券'
                    placement='bottom'
                    onClose={() => setDrawerVisible(false)}
                    open={drawerVisible}
                    height={300}
                  >
                    {availableCoupons.map((coupon, index) => (
                      <CouponItem
                        key={coupon?._id || `coupon-${index}`}
                        value={coupon?._id || `coupon-${index}`}
                      >
                        <CouponInfo>
                          <CouponCode>{coupon?.code || '無代碼'}</CouponCode>
                          <CouponDescription>
                            {coupon?.description || '無描述'}
                          </CouponDescription>
                        </CouponInfo>
                        <Button
                          type='primary'
                          size='small'
                          onClick={() => {
                            handleCouponSelect(coupon._id)
                            setDrawerVisible(false)
                          }}
                        >
                          使用
                        </Button>
                      </CouponItem>
                    ))}
                  </Drawer>
                  <InputGroup style={{ marginTop: '10px' }}>
                    <Input
                      placeholder='輸入優惠券代碼'
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                    />
                    <Button onClick={handleClaimCoupon}>應用優惠券</Button>
                  </InputGroup>
                </>
              ) : (
                <>
                  <Select
                    style={{ width: '100%' }}
                    placeholder='請選擇優惠券'
                    onChange={handleCouponSelect}
                    value={
                      selectedCoupon
                        ? selectedCoupon._id ||
                          `coupon-${availableCoupons.indexOf(selectedCoupon)}`
                        : 'none'
                    }
                  >
                    {renderCouponOptions()}
                  </Select>
                  <InputGroup style={{ marginTop: '10px' }}>
                    <Input
                      placeholder='輸入優惠券代碼'
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                    />
                    <Button onClick={handleClaimCoupon}>應用優惠券</Button>
                  </InputGroup>
                </>
              )}
            </FormItem>

            {shouldShowDeposit(depositInfo, visitCount) && (
              <div>
                <FormItem label='準備匯款？查看並填寫後五碼以確認訂單'>
                  <Flex justify='space-between' align='center'>
                    <Switch
                      checked={hasTransferred}
                      onChange={(checked) => {
                        setHasTransferred(checked)
                        if (!checked) {
                          setDepositConfirmed(false)
                          form.setFieldsValue({ depositBankNumber: undefined })
                        }
                      }}
                      disabled={isConfirmed}
                    />
                  </Flex>
                </FormItem>

                {hasTransferred && (
                  <>
                    <div
                      style={{
                        marginBottom: '20px',
                        padding: '15px',
                        backgroundColor: '#f0f0f0',
                        borderRadius: '5px'
                      }}
                    >
                      <p style={{ color: '#666', marginBottom: '10px' }}>
                        {depositInfo.depositCondition === 'all'
                          ? '此服務需要支付定金'
                          : visitCount === 0
                          ? '首次預約需要支付定金'
                          : `訪問次數 ${visitCount} 次，需要支付定金`}
                      </p>
                      <p>定金金額: ${depositInfo.depositAmount}</p>
                      <p>
                        銀行帳號: {depositInfo.bankName}{' '}
                        {depositInfo.accountNumber}
                        <CopyOutlined
                          style={{
                            marginLeft: '10px',
                            cursor: 'pointer',
                            fontSize: '20px',
                            color: '#1890ff'
                          }}
                          onClick={() =>
                            copyToClipboard(depositInfo.accountNumber)
                          }
                        />
                      </p>
                      {depositInfo.autoCancelHours && (
                        <p>
                          注意：如果在 {depositInfo.autoCancelHours}{' '}
                          小時內未完成匯款，訂單將自動取消。
                        </p>
                      )}
                      {depositInfo.description && (
                        <p>{depositInfo.description}</p>
                      )}
                    </div>
                    <FormItem
                      name='depositBankNumber'
                      rules={[
                        { required: true, message: '請輸入匯款後五碼' },
                        { pattern: /^\d{5}$/, message: '請輸入5位數字' }
                      ]}
                    >
                      <Input
                        placeholder='請輸入匯款後五碼'
                        maxLength={5}
                        disabled={isConfirmed}
                        onChange={(e) =>
                          setDepositConfirmed(e.target.value.length === 5)
                        }
                      />
                    </FormItem>
                  </>
                )}
              </div>
            )}

            <FormItem style={{ textAlign: 'left' }}>
              <Checkbox
                checked={joinLine}
                onChange={(e) => setJoinLine(e.target.checked)}
              >
                加入官方LINE帳號，接收店家
                <span style={{ fontWeight: 'bold' }}>預約提醒</span>！
              </Checkbox>
              <br />
              <div
                style={{ fontSize: '12px', color: '#666', paddingLeft: '20px' }}
              >
                (已加入可不勾選)
              </div>
              <div style={{ marginTop: '10px' }}>
                <a
                  href='https://lin.ee/8nb2EEq'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    src='https://scdn.line-apps.com/n/line_add_friends/btn/zh-Hant.png'
                    alt='加入好友'
                    height='36'
                    border='0'
                  />
                </a>
              </div>
            </FormItem>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                className='booking-summary'
                onClick={prevStep}
                style={{
                  marginTop: '20px',
                  marginRight: '10px',
                  padding: '20px',
                  fontSize: '16px'
                }}
              >
                返回
              </Button>
              {isConfirmed ? (
                <Button
                  style={{
                    marginTop: '20px',
                    padding: '20px',
                    fontSize: '16px'
                  }}
                  onClick={handleCancel}
                >
                  取消
                </Button>
              ) : (
                <Button
                  type='primary'
                  htmlType='submit'
                  style={{
                    marginTop: '20px',
                    padding: '20px',
                    fontSize: '16px'
                  }}
                >
                  確認預約內容
                </Button>
              )}
            </div>
          </Form>
        )}
      </Container>
    </ProtectedRoute>
  )
}

export default BookingStepThree
