import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../hooks/AuthContext'
import { message } from 'antd'
import api from '../utils/api'

const LineBindingPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useAuth()
  const [isBinding, setIsBinding] = useState(false)
  const [bindingSuccess, setBindingSuccess] = useState(false)

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const token = params.get('token')

    if (!token) {
      message.error('無效的綁定請求')
      return
    }

    // 如果未登入，先導向登入頁面
    if (!user) {
      // 保存當前 URL，登入後返回
      localStorage.setItem(
        'redirectAfterLogin',
        location.pathname + location.search
      )
      navigate('/login')
      return
    }

    // 如果已登入且有 token，執行綁定
    const bindAccount = async () => {
      console.log('bindAccount')
      try {
        setIsBinding(true)
        const response = await api.post('/api/auth/bind-account', {
          token,
          userId: user.userId
        })
        console.log('response', response)
        if (!response.data.success) {
          throw new Error('綁定失敗')
        }

        setBindingSuccess(true)
        message.success('LINE 帳號綁定成功！')
      } catch (error) {
        message.error('綁定失敗：' + error.message)
      } finally {
        setIsBinding(false)
      }
    }

    bindAccount()
  }, [location, navigate])

  const handleReturnToLine = () => {
    // 手機版
    if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      window.location.href = 'line://home' // 回到 LINE 主頁
      // 或者 window.location.href = 'line://chat';  // 回到聊天列表
    } else {
      // 電腦版可以導向 LINE 網頁版
      window.location.href = 'https://lin.ee/iuWKFK4'
    }
  }

  if (isBinding) {
    return <div>正在綁定帳號，請稍候...</div>
  }

  return (
    <div style={{ textAlign: 'center', padding: '40px 20px' }}>
      {bindingSuccess ? (
        <>
          <h2>綁定成功！</h2>
          <p>您的帳號已成功與 LINE 綁定</p>
          <button
            onClick={handleReturnToLine}
            style={{
              padding: '10px 20px',
              backgroundColor: '#00B900',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
          >
            返回 LINE
          </button>
        </>
      ) : (
        <div>處理中...</div>
      )}
    </div>
  )
}

export default LineBindingPage
