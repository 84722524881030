import React, { useState, useEffect, useMemo } from 'react'
import api from '../utils/api'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Steps, Button, message } from 'antd'
import BookingStepOne from '../components/BookingStepOne'
import BookingStepTwo from '../components/BookingStepTwo'
import BookingStepThree from '../components/BookingStepThree'
import moment from 'moment'
import { API_BASE_URL } from '../utils/apiutils'
import { getTagColor } from '../utils/utils'
import { useNavigate } from 'react-router-dom'
import Loading from '../utils/Loading'
import {
  SolutionOutlined,
  CalendarOutlined,
  FileDoneOutlined
} from '@ant-design/icons'
import { CheckCircleOutlined } from '@ant-design/icons'
import { useAuth } from '../hooks/AuthContext'
import { BiErrorAlt } from 'react-icons/bi'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet-async'
import { useSuccess } from '../context/SuccessContext'

const { Step } = Steps

const Container = styled.div`
  display: flex;
  font-family: Arial, sans-serif;
  padding: 0 20px;
  @media (max-width: 820px) {
    flex-direction: column;
  }
`

const Content = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
`

const StepContainer = styled.div`
  padding: 30px 20px;
  border-right: 1px solid #eee;
  @media (max-width: 820px) {
    padding: 20px 10px;
    border-right: none;
  }
`

const RightPane = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #f9f9f9;
  @media (max-width: 820px) {
    padding: 0;
    margin: 20px 0;
    width: 100%;
    background-color: #fff;
  }
`

const Summary = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: ${({ $showBorder }) =>
    $showBorder ? '1px solid #d5728a' : 'none'}; /* 動態邊框 */
  transition: border 0.3s ease;
`

const SummaryTitle = styled.h2`
  margin-top: 0;
  color: #333;
`

const SummaryItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  text-align: left;
`

const ServiceText = styled.div`
  padding-bottom: 15px;
  font-size: 14px;
  border-bottom: 1px solid #eee;
  @media (max-width: 820px) {
    font-size: 18px;
  }
`

const Subtitle = styled.div`
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: bold;
  color: #666;
  @media (max-width: 820px) {
    font-size: 15px;
  }
`

const ServiceAddress = styled.div`
  padding-bottom: 15px;
  font-size: 14px;
  color: #666;
  border-bottom: 1px solid #eee;
  @media (max-width: 820px) {
    font-size: 15px;
  }
`

const ServiceName = styled.div`
  font-size: 14px;
  @media (max-width: 820px) {
    font-size: 16px;
    font-weight: 500;
  }
`

const Reminder = styled.p`
  font-size: 12px;
  color: #666;
  margin: 0px 0 10px 0;
  text-align: left;
`

const DepositConfirmed = styled.span`
  color: #ff4d4f;
  margin-left: 10px;
  font-size: 14px;
`
const CardTagContainer = styled.div`
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  margin-bottom: 10px;
`

const CardTag = styled.div`
  background-color: ${(props) => props.color};
  padding: 3px 4px;
  border-radius: 5px;
  margin: 2px 4px;
`

const CardTagText = styled.div`
  font-size: 11px;
  color: #896868;
`

const PriceDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10px;
`

const PriceItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
`

const PriceLabel = styled.span`
  font-size: 16px;
  color: #666;
`

const PriceValue = styled.span`
  font-size: 18px;
  color: ${(props) =>
    props.$isDiscount ? '#c1a268' : props.$isFinal ? '#d5728a' : '#888'};
  font-weight: ${(props) => (props.$isFinal ? 'bold' : 'normal')};
  text-decoration: ${(props) => (props.$isOriginal ? 'line-through' : 'none')};
`

const AdditionalServicesList = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid #eee;
`

const AdditionalServiceItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 14px;
  color: #666;
`

const ServiceItemName = styled.span`
  flex: 1;
`

const ServiceItemPrice = styled.span`
  color: #888;
`
const ServiceItemDetails = styled.div`
  text-align: right;
  color: #888;
`

const BookingService = () => {
  const { username, productId } = useParams()
  const [product, setProduct] = useState(null)
  const [business, setBusiness] = useState(null)
  const [selectedStylist, setSelectedStylist] = useState(null)
  const [selectedDate, setSelectedDate] = useState(moment())
  const [selectedTime, setSelectedTime] = useState(null)
  const [currentStep, setCurrentStep] = useState(0)
  const [userInfo, setUserInfo] = useState(null)
  const [isInfoConfirmed, setIsInfoConfirmed] = useState(false)
  const [depositSettings, setDepositSettings] = useState(null)
  const [depositConfirmed, setDepositConfirmed] = useState(false)
  const [depositBankNumber, setDepositBankNumber] = useState(null)
  const navigate = useNavigate()
  const { user } = useAuth()
  const [errorMessage, setErrorMessage] = useState(null)
  const [availableCoupons, setAvailableCoupons] = useState([])
  const [selectedCoupon, setSelectedCoupon] = useState(null)
  const [discountedPrice, setDiscountedPrice] = useState(0)
  const [couponMessage, setCouponMessage] = useState(null)
  const [couponCode, setCouponCode] = useState('')
  const [couponId, setCouponId] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [joinLine, setJoinLine] = useState(false)
  const [additionalServices, setAdditionalServices] = useState([])
  const { markSuccess } = useSuccess()

  useEffect(() => {
    // 獲取產品和商家信息
    api
      .get(`${API_BASE_URL}/api/business_C/${username}`)
      .then((response) => {
        const businessData = response.data
        const productData = businessData.products.find(
          (product) => product._id === productId
        )
        setProduct(productData)
        setBusiness(businessData)
        // 設置depositSettings
        if (businessData.depositSettings) {
          setDepositSettings(businessData.depositSettings)
        }
      })
      .catch((error) => {
        console.error('Error fetching product or business details:', error)
      })

    // 獲取可用優惠券
    api
      .get(`${API_BASE_URL}/api/user/available-coupons`)
      .then((response) => {
        setAvailableCoupons(response.data.coupons ? response.data.coupons : [])
      })
      .catch((error) => {
        console.error('獲取可用優惠券時出錯:', error)
        setAvailableCoupons([])
      })
  }, [username, productId])

  const handleCouponChange = (newCouponCode, newCouponId) => {
    setCouponCode(newCouponCode)
    setCouponId(newCouponId)
    if (newCouponId && newCouponId !== 'none') {
      validateCoupon(newCouponId)
    } else {
      setSelectedCoupon(null)
      setDiscountedPrice(0)
      setCouponMessage(null)
    }
  }

  const validateCoupon = (couponId) => {
    const coupon = availableCoupons.find(
      (c) =>
        c &&
        (c._id === couponId ||
          `coupon-${availableCoupons.indexOf(c)}` === couponId)
    )
    if (!coupon) {
      console.error('無效的優惠券 ID')
      setCouponMessage('無效的優惠券')
      setSelectedCoupon(null)
      setDiscountedPrice(0)
      return
    }

    api
      .post(`${API_BASE_URL}/api/user/validate-coupon`, {
        couponId: coupon._id,
        businessId: business._id,
        productIds: [productId],
        totalAmount: product.price
      })
      .then((response) => {
        setSelectedCoupon(response.data.coupon)
        setDiscountedPrice(response.data.discount)
        setCouponMessage('優惠券使用成功')
      })
      .catch((error) => {
        console.error('驗證優惠券時出錯:', error)
        setCouponMessage(error.response?.data?.error || '優惠券驗證失敗')
        setSelectedCoupon(null)
        setDiscountedPrice(0)
      })
  }

  const handleBooking = () => {
    if (!business || !product || isSubmitting) return

    setIsSubmitting(true)
    setErrorMessage(null)

    const bookingData = {
      businessId: business._id,
      productId,
      additionalServiceIds: additionalServices.map((service) => service._id),
      stylistId: selectedStylist,
      date: selectedDate.format('YYYY-MM-DD'),
      time: selectedTime,
      userId: user.userId,
      userInfo,
      depositBankNumber: depositSettings?.isEnabled
        ? depositBankNumber
        : undefined,
      couponCode: couponCode,
      couponId: couponId,
      joinLine
    }

    api
      .post(`${API_BASE_URL}/api/client/bookings`, bookingData)
      .then((response) => {
        message.success('預訂成功！')
        if (joinLine) {
          window.open('https://lin.ee/UazJgGl', '_blank')
        }
        markSuccess('booking')
        navigate('/booking-success', {
          state: {
            locationName: business.name,
            locationAddress: business.address,
            selectedDate: selectedDate.format('YYYY-MM-DD'),
            selectedTime: selectedTime
          }
        })
      })
      .catch((error) => {
        console.error('Error creating booking:', error)
        setErrorMessage(error.response?.data?.error || '預約失敗，請稍後再試')
        message.error(error.response?.data?.error || '預約失敗，請稍後再試')
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const nextStep = () => {
    setCurrentStep(currentStep + 1)
  }

  const prevStep = () => {
    setCurrentStep(currentStep - 1)
  }

  const handleConfirm = (info) => {
    setUserInfo(info)
    setIsInfoConfirmed(true)
    setDepositConfirmed(info.depositConfirmed)
    setDepositBankNumber(info.depositBankNumber)
    setJoinLine(info.joinLine)
  }

  const handleCancelConfirm = () => {
    setIsInfoConfirmed(false)
  }

  const handleStepClick = (step) => {
    if (step <= currentStep) {
      setCurrentStep(step)
    }
  }

  const calculateFinalPrice = () => {
    return product.price - discountedPrice
  }

  const handleClaimCoupon = (couponCode, callback) => {
    api
      .post(`${API_BASE_URL}/api/user/claim-coupon`, {
        couponCode,
        businessId: business._id
      })
      .then((response) => {
        const newCoupon = response.data.newCoupon

        // 使用函數式更新來確保我們總是基於最新的狀態進行更新
        setAvailableCoupons((prevCoupons) => {
          const updatedCoupons = [...prevCoupons, newCoupon]

          // 在這裡調用 validateAndApplyCoupon，確保它使用最新的 availableCoupons
          setTimeout(
            () => validateAndApplyCoupon(newCoupon._id, updatedCoupons),
            0
          )

          return updatedCoupons
        })

        setSelectedCoupon(newCoupon)
        setCouponCode(newCoupon.code)
        setCouponId(newCoupon._id)

        callback(true, '優惠券新增成功', newCoupon)
      })
      .catch((error) => {
        console.error('新增優惠券時出錯:', error)
        callback(false, error.response?.data?.error || '新增優惠券失敗')
      })
  }

  const validateAndApplyCoupon = (
    couponId,
    currentCoupons = availableCoupons
  ) => {
    const coupon = currentCoupons.find(
      (c) =>
        c &&
        (c._id === couponId ||
          `coupon-${currentCoupons.indexOf(c)}` === couponId)
    )

    if (!coupon) {
      console.error('無效的優惠券 ID:', couponId)
      setCouponMessage('無效的優惠券')
      setSelectedCoupon(null)
      setDiscountedPrice(0)
      return
    }

    api
      .post(`${API_BASE_URL}/api/user/validate-coupon`, {
        couponId: coupon._id,
        businessId: business._id,
        productIds: [productId],
        totalAmount: product.price
      })
      .then((response) => {
        setSelectedCoupon(response.data.coupon)
        setDiscountedPrice(response.data.discount)
        setCouponMessage('優惠券使用成功')
        setCouponCode(coupon.code)
        setCouponId(coupon._id)
      })
      .catch((error) => {
        console.error('驗證優惠券時出錯:', error)
        console.error('錯誤詳情:', error.response?.data)
        setCouponMessage(error.response?.data?.error || '優惠券驗證失敗')
        setSelectedCoupon(null)
        setDiscountedPrice(0)
        setCouponCode('')
        setCouponId(null)
      })
  }

  const filteredProducts = useMemo(() => {
    return business?.products?.filter((p) => p._id !== product._id) || []
  }, [business?.products, product?._id])

  const calculateTotalPrice = useMemo(() => {
    const mainPrice = product?.price || 0
    const additionalPrice = additionalServices.reduce((total, service) => {
      return total + (service.price || 0)
    }, 0)
    return mainPrice + additionalPrice
  }, [product?.price, additionalServices])

  if (!product || !business) return <Loading />

  return (
    <Container>
      <Helmet>
        <title>{`${business.name}｜${product.name}預約服務｜線上查詢預約平台 - 美感｜BeauteFeel`}</title>
        <meta
          name='description'
          content={`全台美容線上預約平台，不論美甲美容美髮美睫霧唇霧眉，輕鬆一鍵查詢並完成預約，找到最符合您需求的美容專家!`}
        />
      </Helmet>
      <Content>
        <StepContainer>
          <Steps
            current={currentStep}
            responsive={false}
            onChange={handleStepClick}
          >
            <Step title='選擇員工' icon={<SolutionOutlined />} />
            <Step title='選擇日期' icon={<CalendarOutlined />} />
            <Step title='確認信息' icon={<FileDoneOutlined />} />
          </Steps>
        </StepContainer>
        {currentStep === 0 && (
          <BookingStepOne
            business={business}
            product={product}
            setSelectedStylist={setSelectedStylist}
            nextStep={nextStep}
            depositSettings={depositSettings}
            additionalServices={additionalServices}
            setAdditionalServices={setAdditionalServices}
          />
        )}
        {currentStep === 1 && (
          <BookingStepTwo
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
            nextStep={nextStep}
            prevStep={prevStep}
            storeId={business._id}
            storeUsername={business.username}
            staffId={selectedStylist}
            product={product}
            additionalServices={additionalServices}
          />
        )}
        {currentStep === 2 && (
          <BookingStepThree
            prevStep={prevStep}
            onConfirm={handleConfirm}
            onCancel={handleCancelConfirm}
            depositInfo={depositSettings}
            availableCoupons={availableCoupons}
            businessId={business._id}
            onCouponChange={handleCouponChange}
            onClaimCoupon={handleClaimCoupon}
          />
        )}
      </Content>

      <RightPane>
        {errorMessage && (
          <div style={{ color: 'red', marginBottom: '10px' }}>
            <BiErrorAlt /> {errorMessage}
          </div>
        )}
        {couponMessage && isMobile && (
          <div
            style={{
              color: selectedCoupon ? '#C1A268' : '#bf1c5f',
              marginBottom: '10px',
              padding: '10px',
              backgroundColor: '#f0f0f0',
              borderRadius: '5px'
            }}
          >
            {couponMessage}
          </div>
        )}
        <Summary $showBorder={isInfoConfirmed}>
          <SummaryTitle>預訂詳情</SummaryTitle>
          <SummaryItem>
            <Subtitle>服務</Subtitle>
            <ServiceText>
              {product.name} {product.duration} 分鐘
            </ServiceText>
            {additionalServices.length > 0 && (
              <AdditionalServicesList>
                <Subtitle>加選服務</Subtitle>
                {[...additionalServices]
                  .sort((a, b) => (a.orderIndex || 0) - (b.orderIndex || 0))
                  .map((service) => (
                    <AdditionalServiceItem key={service._id}>
                      <ServiceItemName>
                        {service.name} {service.duration}分鐘
                      </ServiceItemName>
                      <ServiceItemPrice>${service.price}</ServiceItemPrice>
                    </AdditionalServiceItem>
                  ))}
              </AdditionalServicesList>
            )}
          </SummaryItem>
          <SummaryItem>
            <Subtitle>標籤</Subtitle>
            <ServiceText>
              <CardTagContainer>
                {product.tags.map((tag, index) => (
                  <CardTag key={index} color={getTagColor(tag)}>
                    <CardTagText>{tag}</CardTagText>
                  </CardTag>
                ))}
              </CardTagContainer>
            </ServiceText>
          </SummaryItem>
          <SummaryItem>
            <Subtitle>地點</Subtitle>
            <ServiceName>{business.name}</ServiceName>
            <ServiceAddress>{business.address}</ServiceAddress>
          </SummaryItem>
          {selectedStylist && (
            <SummaryItem>
              <Subtitle>員工</Subtitle>
              <ServiceText>
                {
                  business.stylists.find(
                    (stylist) => stylist._id === selectedStylist
                  ).name
                }
              </ServiceText>
            </SummaryItem>
          )}
          {selectedTime && (
            <SummaryItem>
              <Subtitle>預約時間</Subtitle>
              <ServiceText>
                {selectedDate.format('YYYY-MM-DD')} {selectedTime}
              </ServiceText>
            </SummaryItem>
          )}
          <SummaryItem>
            <Subtitle>價格詳情</Subtitle>
            <PriceDetails>
              <PriceItem>
                <PriceLabel>主要服務:</PriceLabel>
                <PriceValue>${product.price}</PriceValue>
              </PriceItem>

              {additionalServices.length > 0 && (
                <PriceItem>
                  <PriceLabel>額外服務:</PriceLabel>
                  <PriceValue>
                    $
                    {additionalServices.reduce(
                      (sum, service) => sum + service.price,
                      0
                    )}
                  </PriceValue>
                </PriceItem>
              )}

              {discountedPrice > 0 && (
                <>
                  <PriceItem>
                    <PriceLabel>小計:</PriceLabel>
                    <PriceValue $isOriginal>${calculateTotalPrice}</PriceValue>
                  </PriceItem>
                  <PriceItem>
                    <PriceLabel>優惠券折扣:</PriceLabel>
                    <PriceValue $isDiscount>-${discountedPrice}</PriceValue>
                  </PriceItem>
                </>
              )}

              <PriceItem>
                <PriceLabel>
                  {discountedPrice > 0 ? '折扣後總金額:' : '總金額:'}
                </PriceLabel>
                <PriceValue $isFinal>
                  ${calculateTotalPrice - discountedPrice}
                </PriceValue>
              </PriceItem>
            </PriceDetails>
            {couponMessage && (
              <div
                style={{
                  color: selectedCoupon ? '#C1A268' : '#bf1c5f',
                  marginBottom: '10px',
                  padding: '10px',
                  backgroundColor: '#f0f0f0',
                  borderRadius: '5px'
                }}
              >
                {couponMessage}
              </div>
            )}
            {selectedCoupon && (
              <div
                style={{
                  fontSize: '14px',
                  color: '#52c41a',
                  marginTop: '5px',
                  textAlign: 'right'
                }}
              >
                已使用優惠券: {selectedCoupon.code}
              </div>
            )}
          </SummaryItem>
          {depositConfirmed && (
            <DepositConfirmed>
              <CheckCircleOutlined /> 已匯款
            </DepositConfirmed>
          )}
        </Summary>
        <Button
          type='primary'
          onClick={handleBooking}
          disabled={
            !selectedDate || !selectedTime || !isInfoConfirmed || isSubmitting
          }
          style={{
            width: '100%',
            margin: '10px 0 20px',
            fontSize: '18px',
            padding: '20px'
          }}
        >
          {isSubmitting ? '預約中...' : '送出預約'}
        </Button>
        <Reminder>
          請在預約送出前確認您的預約資訊，點擊送出後代表您同意店家與平台獲取您的個人資料，並請您準時赴約。
        </Reminder>
      </RightPane>
    </Container>
  )
}

export default BookingService
