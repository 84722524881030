import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { PHOTO_URL } from '../utils/apiutils'
import { AddToCalendarButton } from 'add-to-calendar-button-react'
import { useSuccess } from '../context/SuccessContext'

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
`

const Title = styled.h1`
  color: #333;
  margin-bottom: 10px;
`

const Message = styled.p`
  color: #666;
  font-size: 18px;
  margin-bottom: 40px;
`

const CalendarContainer = styled.div`
  width: 300px;
  margin-bottom: 20px;
`

const Image = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
`

const BookingSuccessPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { successStates, resetSuccess } = useSuccess()

  useEffect(() => {
    if (!successStates.booking || !location.state) {
      navigate('/', { replace: true })
      return
    }

    return () => {
      if (window.location.pathname !== '/booking-success') {
        resetSuccess('booking')
      }
    }
  }, [successStates.booking, location.state, navigate, resetSuccess])

  if (!location.state) {
    return null
  }

  return (
    <SuccessContainer>
      <Image
        src={`${PHOTO_URL}/public/assets/welldone.svg`}
        alt='Success'
      ></Image>
      <Title>謝謝！</Title>
      <Message>預訂成功！感謝您的信任，期待您的光臨！</Message>

      <CalendarContainer>添加到行事曆</CalendarContainer>

      <AddToCalendarButton
        name={`在${location.state.locationName}的預約`}
        options={['Apple', 'Google']}
        location={location.state.locationAddress}
        startDate={location.state.selectedDate}
        endDate={location.state.selectedDate}
        startTime={location.state.selectedTime}
        endTime={location.state.selectedTime}
        timeZone='Asia/Taipei'
      ></AddToCalendarButton>
    </SuccessContainer>
  )
}

export default BookingSuccessPage
